import { useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import {
  Button,
  Col,
  FormGroup,
  InPageAlert,
  Input,
  Row,
  Select,
  Textarea,
} from '@snsw/react-component-library';
import { DocumentIcon } from 'ams-common';
import { useFormik } from 'formik';
import { QueryKey } from 'src/api/constants';
import { ClientOptions } from 'src/components/clients/types';
import {
  CLASSIFICATION_OPTIONS_AUDITOR,
  CLASSIFICATION_OPTIONS_CUSTOMER,
} from 'src/constants';
import { useUploadDocument, useUserContext } from 'src/hooks';
import { UserLoginType } from 'src/types';
import { HandleEditDocument, OperationType } from 'src/types/documentTypes';
import getContent, { getContentWithReplacements } from 'src/utils/contentUtils';
import * as Yup from 'yup';

import { MatterDocuments } from '../documentTableWithAccordion/types';

import {
  DOCUMENT_DETAILS_UPDATE_FAILED,
  FILE_UPLOAD_FAILED,
  FILE_VIRUS_DETECTION_ERROR,
} from './constants';
import {
  checkIfActualCustomersEqualsToUpdatedCustomers,
  checkIfFileAlreadyUploaded,
  constructRequestBody,
  getActualCustomers,
  getClassificationOptions,
  getClassificationText,
  getClassificationValueFromApiSectionName,
  getClientOptions,
  getDocumentNamesWithClassification,
  getEditDocumentRequestBody,
  getUpdatedCustomers,
} from './helpers';
import { useSingleDocumentModal } from './hooks';
import { ButtonGroup, FileName, StyledIcon, StyledModal } from './styles';
import { ModalData } from './types';

type SingleDocumentModalProps = {
  matterUId: string | null;
  matterId: string | null;
  close: (documentName?: string) => void;
  handleFileUploadValue?: ({
    fileName,
    docId,
    fileSize,
  }: {
    fileName: string;
    docId: string | null;
    fileSize: number;
  }) => void;
  file?: File | null;
  clientOptions: ClientOptions;
  documentsData?: MatterDocuments;
  operationType?: OperationType;
  editDocumentDetails: HandleEditDocument | null;
  selectedCustomer?: string;
};

const initialValues: ModalData = {
  customer: 'all',
  classification: 'select',
  description: '',
  documentName: '',
  documentId: '',
};

const SingleDocumentModal = ({
  matterUId,
  matterId,
  close,
  handleFileUploadValue,
  file,
  clientOptions,
  documentsData,
  operationType = OperationType.Add,
  editDocumentDetails,
  selectedCustomer,
}: SingleDocumentModalProps) => {
  const [errorMessage, setErrorMessage] = useState('');
  const userContext = useUserContext();

  const { editDocument } = useSingleDocumentModal();
  const { uploadDocumentByMatterUId, uploadFile, getPresignedUrl } =
    useUploadDocument();

  const queryClient = useQueryClient();
  const isCustomer = userContext?.userType === UserLoginType.Customer;

  // const isCustomer = false;
  const [messageCharCount, setMessageCharCount] = useState(0);

  const classificationOptions = useMemo(() => {
    return getClassificationOptions(isCustomer, operationType);
  }, [isCustomer, operationType]);

  const {
    values,
    handleChange,
    handleSubmit,
    errors,
    submitCount,
    isValid,
    setValues,
    isSubmitting,
  } = useFormik({
    initialValues: {
      ...initialValues,
      customer:
        selectedCustomer && selectedCustomer.split(',').length > 1
          ? 'all'
          : selectedCustomer || ' ',
    },
    validationSchema: Yup.object({
      description: Yup.string().when('classification', {
        is: (classification) => classification === 'other',
        then: (schema) => schema.required('This field is required'),
        otherwise: (schema) => schema.notRequired(),
      }),
      customer: Yup.string().required(),
      classification: Yup.string()
        .oneOf(
          [
            ...CLASSIFICATION_OPTIONS_AUDITOR,
            ...CLASSIFICATION_OPTIONS_CUSTOMER,
          ].map(({ value }) => value),
        )
        .required(),
    }),
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (modalValues: ModalData) => {
      if (isValid) {
        try {
          const documentNamesWithClassification = documentsData
            ? getDocumentNamesWithClassification(
                documentsData,
                values.customer,
                clientOptions,
              )
            : [];

          const fileName =
            operationType === OperationType.Add
              ? file?.name || ''
              : values.documentName;

          const classificationText = getClassificationText(
            [
              ...CLASSIFICATION_OPTIONS_AUDITOR,
              ...CLASSIFICATION_OPTIONS_CUSTOMER,
            ],
            values.classification,
          );

          const actualClassification = editDocumentDetails?.classification
            ? getClassificationValueFromApiSectionName(
                classificationOptions,
                editDocumentDetails?.classification,
              )
            : '';

          const actualCustomers = getActualCustomers(
            editDocumentDetails?.clientId,
          );

          const updatedCustomers = getUpdatedCustomers(
            clientOptions,
            values.customer,
          );

          /*
            before doing file upload check
              - this condition only when operationTye is edit
              - TRUE
              - if customer dropdown update is not matched with actual customer
              - if classification update is not matched with actual classification
          */

          const conditionBeforeFileUploadCheck =
            operationType === OperationType.Edit
              ? actualClassification === values.classification &&
                checkIfActualCustomersEqualsToUpdatedCustomers(
                  actualCustomers,
                  updatedCustomers,
                )
              : false;

          if (
            !conditionBeforeFileUploadCheck &&
            checkIfFileAlreadyUploaded(
              documentNamesWithClassification,
              fileName,
              classificationOptions,
              classificationText,
            )
          ) {
            setErrorMessage(
              'Please reupload the file with a different file name, as this file already exists',
            );
            return;
          }

          // Function to handle the Add operation
          const handleAddOperation = async () => {
            if (!file) {
              console.error('No file selected');
              return;
            }
            if (!matterId || !matterUId) {
              setErrorMessage('Matter Id or Matter UId is unavailable.');
              return;
            }

            const [firstUrl] = await getPresignedUrl(matterUId, isCustomer);
            const { s3Key, url } = firstUrl;

            if (!url || !s3Key) {
              setErrorMessage(FILE_UPLOAD_FAILED);
              return;
            }
            try {
              await uploadFile(url, file);
            } catch (uploadError) {
              setErrorMessage(FILE_UPLOAD_FAILED);
              return;
            }

            const requestBody = constructRequestBody(
              matterId,
              s3Key,
              file.name,
              modalValues,
              clientOptions,
              isCustomer,
            );

            let uploadedDocumentResponse: {
              data: {
                id: string | null;
                s3Key: string | null;
                errorMessage: string | null;
              }[];
            };
            try {
              uploadedDocumentResponse = (await uploadDocumentByMatterUId(
                matterUId,
                requestBody,
                isCustomer,
              )) as {
                data: {
                  id: string | null;
                  s3Key: string | null;
                  errorMessage: string | null;
                }[];
              };

              if (!uploadedDocumentResponse?.data?.length) {
                setErrorMessage(FILE_UPLOAD_FAILED);
                return;
              }
              if (
                uploadedDocumentResponse?.data[0].errorMessage ===
                'File scan having issue'
              ) {
                setErrorMessage(FILE_VIRUS_DETECTION_ERROR);
                return;
              }
            } catch (error) {
              setErrorMessage(FILE_UPLOAD_FAILED);
              return;
            }

            const documentResponse = {
              fileName: file.name,
              fileSize: file.size,
              docId: uploadedDocumentResponse.data[0].id,
            };

            if (documentResponse.docId) {
              handleFileUploadValue?.(documentResponse);
            }

            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            handleInvalidationAndClose(
              [QueryKey.MATTER_DOCUMENTS, QueryKey.MATTER_TRIM_DOCUMENTS],
              fileName,
            );
          };

          // Function to handle the Edit operation
          const handleEditOperation = async () => {
            if (!matterUId) {
              setErrorMessage('Matter Id is unavailable.');
              return;
            }

            const editDocumentRequestBody = getEditDocumentRequestBody(
              values,
              clientOptions,
              isCustomer,
            );
            try {
              await editDocument(
                matterUId,
                values.documentId,
                editDocumentRequestBody,
                isCustomer,
              );
            } catch (fileEditError) {
              setErrorMessage(DOCUMENT_DETAILS_UPDATE_FAILED);
              return;
            }
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            handleInvalidationAndClose(
              [QueryKey.MATTER_DOCUMENTS, QueryKey.MATTER_TRIM_DOCUMENTS],
              fileName,
            );
          };

          // Execute the appropriate operation based on the operation type
          if (operationType === OperationType.Add) {
            await handleAddOperation();
          } else {
            await handleEditOperation();
          }
        } catch (error) {
          setErrorMessage(
            `${
              operationType === OperationType.Add ? 'Uploading' : 'Updating'
            } document failed. Please try again later.`,
          );
        }
      }
    },
  });

  useEffect(() => {
    if (clientOptions?.length === 1) {
      setValues({
        ...initialValues,
        customer: clientOptions[0].value,
      });
    }
  }, [clientOptions, setValues]);

  useEffect(() => {
    if (!editDocumentDetails) {
      return;
    }

    const { clientId, classification, description, documentName, documentId } =
      editDocumentDetails;

    const isClientOptionsAvailable = clientOptions && clientOptions.length > 1;
    const isClientIdIncluded =
      isClientOptionsAvailable &&
      clientOptions?.every(({ value }) => clientId.split(',').includes(value));
    // eslint-disable-next-line no-nested-ternary

    // eslint-disable-next-line no-nested-ternary
    const customer = isClientOptionsAvailable
      ? isClientIdIncluded
        ? 'all'
        : clientId
      : clientId;

    const selectedClient =
      selectedCustomer && selectedCustomer.split(',').length > 1
        ? 'all'
        : selectedCustomer || ' ';

    setValues({
      ...initialValues,
      classification: getClassificationValueFromApiSectionName(
        classificationOptions,
        classification,
      ),
      description,
      customer: editDocumentDetails ? customer : selectedClient,
      documentName,
      documentId,
    });

    setMessageCharCount(description.length);
  }, [
    classificationOptions,
    clientOptions,
    editDocumentDetails,
    selectedCustomer,
    setValues,
  ]);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    handleSubmit();
  };

  const handleInvalidationAndClose = (
    queryKey: QueryKey[],
    documentName: string,
  ) => {
    queryKey.forEach((key) => {
      queryClient.invalidateQueries([key, matterUId]);
    });
    close(documentName);
  };

  const infoText =
    operationType === OperationType.Add ? 'Uploading' : 'Updating';

  return (
    <form>
      <StyledModal
        id={getContent(
          `matter.correspondence.submitAuditCommencementDocuments.uploadSingleDocument.modal.${operationType}.id`,
        )}
        title={getContent(
          `matter.correspondence.submitAuditCommencementDocuments.uploadSingleDocument.modal.${operationType}.title`,
        )}
        description={getContent(
          `matter.correspondence.submitAuditCommencementDocuments.uploadSingleDocument.modal.${operationType}.subtitle`,
        )}
        buttons={[]}
      >
        {operationType === OperationType.Add && (
          <>
            <p>
              {getContent(
                'matter.correspondence.submitAuditCommencementDocuments.uploadSingleDocument.modal.required.text',
              )}
            </p>
            <FileName>
              <DocumentIcon />
              {file?.name}
            </FileName>
          </>
        )}
        {operationType === OperationType.Edit && (
          <Row>
            <Col span={0.8}>
              <StyledIcon>
                <DocumentIcon />
              </StyledIcon>
            </Col>
            <FileName>{values?.documentName}</FileName>
          </Row>
        )}
        <Row>
          <Col span={8}>
            <FormGroup
              errorMessage={getContent(
                'matter.correspondence.submitAuditCommencementDocuments.uploadSingleDocument.modal.client.errorMessage',
              )}
              id="customer"
              label={getContent(
                'matter.correspondence.submitAuditCommencementDocuments.uploadSingleDocument.modal.client.label',
              )}
              hasError={submitCount > 0 && errors.customer}
            >
              {clientOptions && clientOptions.length > 1 ? (
                <Select
                  name="customer"
                  value={values.customer}
                  options={getClientOptions(clientOptions)}
                  onChange={handleChange}
                />
              ) : (
                <Input
                  name="customer"
                  value={
                    clientOptions &&
                    `${clientOptions[0].value} ${clientOptions[0].text}`
                  }
                  inputWidth="xl"
                  placeholder="Please enter entity name"
                />
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <FormGroup
              errorMessage={getContent(
                'matter.correspondence.submitAuditCommencementDocuments.uploadSingleDocument.modal.classification.errorMessage',
              )}
              id={getContent(
                'matter.correspondence.submitAuditCommencementDocuments.uploadSingleDocument.modal.classification.id',
              )}
              label={getContent(
                'matter.correspondence.submitAuditCommencementDocuments.uploadSingleDocument.modal.classification.label',
              )}
              hasError={submitCount > 0 && errors.classification}
            >
              <Select
                name="classification"
                inputWidth="xl"
                value={values.classification}
                onChange={handleChange}
                options={classificationOptions}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup
              helpMessage={getContentWithReplacements(
                'matter.correspondence.submitAuditCommencementDocuments.uploadSingleDocument.modal.description.helpMessage',
                {
                  remChar: (175 - messageCharCount).toString(),
                },
              )}
              errorMessage={getContent(
                'matter.correspondence.submitAuditCommencementDocuments.uploadSingleDocument.modal.description.errorMessage',
              )}
              id={getContent(
                'matter.correspondence.submitAuditCommencementDocuments.uploadSingleDocument.modal.description.id',
              )}
              label={getContent(
                'matter.correspondence.submitAuditCommencementDocuments.uploadSingleDocument.modal.description.label',
              )}
              hasError={submitCount > 0 && errors.description}
            >
              <Textarea
                value={values.description}
                onChange={(e) => {
                  setMessageCharCount(e.target.value.length);
                  handleChange(e);
                }}
                maxLength={175}
              />
            </FormGroup>
          </Col>
        </Row>
        {!isSubmitting && errorMessage ? (
          <Row>
            <Col>
              <InPageAlert variant="error" title="Error." compact>
                <p>{errorMessage}</p>
              </InPageAlert>
            </Col>
          </Row>
        ) : null}
        {isSubmitting ? (
          <Row>
            <Col>
              <InPageAlert variant="info" title={`${infoText}.`} compact>
                <p>Document is {infoText} please wait...</p>
              </InPageAlert>
            </Col>
          </Row>
        ) : null}
        <Row>
          <Col>
            <ButtonGroup>
              <Button
                variant="secondary"
                onClick={close}
                disabled={isSubmitting}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                onClick={handleFormSubmit}
                disabled={isSubmitting}
              >
                {getContent(
                  `matter.correspondence.submitAuditCommencementDocuments.uploadSingleDocument.modal.primary.button.${operationType}.label`,
                )}
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
      </StyledModal>
    </form>
  );
};

export default SingleDocumentModal;
