/* eslint-disable @typescript-eslint/no-use-before-define */
import { useEffect, useRef, useState } from 'react';
import {
  Button,
  Col,
  ComponentLoader,
  FormGroup,
  Heading,
  InPageAlert,
  Input,
  Row,
} from '@snsw/react-component-library';
import { ErrorSummary } from 'ams-common';
import { useFormik } from 'formik';
import Captcha from 'src/components/captcha';
import { waitToLoadAwsWaf } from 'src/components/captcha/awsWaf';
import environments from 'src/environments';
import getContent from 'src/utils/contentUtils';
import * as Yup from 'yup';

import {
  CustomerAuthFormFieldLabels,
  CustomerAuthFormFieldNames,
} from '../constants';
import {
  ButtonGroup,
  NoStyleButton,
  StyledErrorContainer,
} from '../onboarding/styles';
import { PrivacyCollectionModal } from '../onboarding/termsOfUse/privacyCollectionModal';
import { RegistrationState } from '../types';

import CustomerAuthSuccessModal from './modal/customerAuthSuccessModal';
import { useCustomerAuthForm } from './hooks';
import { CustomerAuthFormData } from './types';

const customerAuthFormInitValues: CustomerAuthFormData = {
  [CustomerAuthFormFieldNames.clientId]: null,
  [CustomerAuthFormFieldNames.correspondenceId]: null,
  [CustomerAuthFormFieldNames.emailAddress]: '',
};

const customerAuthFormSchema = Yup.object({
  [CustomerAuthFormFieldNames.clientId]: Yup.number()
    .required(getContent('customerAuthForm.clientId.errorMessage.required'))
    .typeError('Enter a valid client ID'),
  [CustomerAuthFormFieldNames.correspondenceId]: Yup.number()
    .required(
      getContent('customerAuthForm.correspondenceId.errorMessage.required'),
    )
    .typeError('Enter a valid correspondence ID'),
  [CustomerAuthFormFieldNames.emailAddress]: Yup.string()
    .email(getContent('customerAuthForm.emailAddress.errorMessage.invalid'))
    .required(
      getContent('customerAuthForm.emailAddress.errorMessage.required'),
    ),
});

type CustomerAuthFormTypes = {
  onRegistrationSuccess: (success: boolean) => void;
  isOnboarding?: boolean;
  setRegistration?: React.Dispatch<
    React.SetStateAction<RegistrationState | null>
  >;
  registration?: RegistrationState | null;
};

const CustomerAuthForm = ({
  onRegistrationSuccess,
  isOnboarding,
  registration,
  setRegistration,
}: CustomerAuthFormTypes) => {
  const errorSummaryRef = useRef<HTMLDivElement>(null);
  const [authSuccess, setAuthSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [submitCaptchaAction, setSubmitCaptchaAction] = useState<
    null | ((wafToken: string) => void)
  >(null);
  const enableWaf = environments.useAwsWafCaptcha && submitCaptchaAction;
  const [privacyCollectionModalOpen, setprivacyCollectionModalOpen] =
    useState(false);

  useEffect(() => {
    if (environments.useAwsWafCaptcha) {
      const script = document.createElement('script');
      script.src = `${environments.captchaScriptUrl}/jsapi.js`;
      script.type = 'text/javascript';
      script.defer = true;
      document.head.appendChild(script);

      return () => {
        if (script) {
          document.head.removeChild(script);
        }
      };
    }
    return undefined;
  }, []);

  const navigateSuccess = (statusCode: number) => {
    if (statusCode === 200) {
      setAuthSuccess(true);
    } else {
      setAuthSuccess(false);
    }
  };

  const onError = (data: {
    status: number;
    code: string;
    messages?: string[];
  }) => {
    const { code } = data;

    switch (code) {
      case 'INVALID_CORRO':
      case 'INVALID_CLIENT':
      case 'CANCELLED_CLIENT':
        setErrorMessage('Client ID and/or Correspondence ID is incorrect');
        break;
      case 'INVALID_CONTACT':
        setErrorMessage('Email ID is incorrect');
        break;
      case 'ACCOUNT_LOCKED':
        setErrorMessage(
          'Your account has reached the maximum number of failed login attempts and has been temporarily locked. Please try again after 30 minutes.',
        );
        break;
      default:
        setErrorMessage('Failed to validate customer details');
        break;
    }
  };

  const { mutate: createCustomerAuth, isLoading: isCustomerAuthLoading } =
    useCustomerAuthForm(navigateSuccess, onError);

  const { handleChange, errors, submitCount, values, submitForm, isValid } =
    useFormik({
      initialValues: {
        ...customerAuthFormInitValues,
      },
      validationSchema: customerAuthFormSchema,
      validateOnBlur: false,
      validateOnChange: false,
      onSubmit: async (submittedValues) => {
        try {
          // Reusable function to handle the form submission
          const submitFormWithValues = async (wafToken?: string) => {
            if (
              !submittedValues.clientId ||
              !submittedValues.corroId ||
              !submittedValues.contactEmail
            ) {
              console.error('Missing required fields for form submission');
              return;
            }

            await createCustomerAuth([submittedValues, wafToken]);
            setRegistration?.({
              ...registration,
              [CustomerAuthFormFieldNames.clientId]: submittedValues.clientId,
              [CustomerAuthFormFieldNames.correspondenceId]:
                submittedValues.corroId,
              [CustomerAuthFormFieldNames.emailAddress]:
                submittedValues.contactEmail,
            });
          };

          // If CAPTCHA is enabled, load it and proceed with submission
          if (environments.useAwsWafCaptcha) {
            const loaded = await waitToLoadAwsWaf();
            if (!loaded) {
              console.error(
                'Could not submit the form because AWS WAF Captcha is not available',
              );
              return;
            }

            // Set the action to handle CAPTCHA token
            setSubmitCaptchaAction(() => async (wafToken: string) => {
              setSubmitCaptchaAction(null);
              await submitFormWithValues(wafToken);
            });
          } else {
            // If CAPTCHA is not enabled, just submit the form
            await submitFormWithValues();
          }
        } catch (error) {
          console.error('An error occurred during form submission:', error);
        }
      },
    });

  const handleFormSubmit = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();
    submitForm();
    if (!isValid) {
      errorSummaryRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      <ComponentLoader active={isCustomerAuthLoading} fullPage />
      <form>
        <StyledErrorContainer
          ref={errorSummaryRef}
          showError={!isValid && submitCount > 0}
        />
        <ErrorSummary errors={errors} />
        {isOnboarding && (
          <Heading level={3}>
            {getContent('customerAuthForm.onboarding.heading.main')}
          </Heading>
        )}
        {errorMessage ? (
          <InPageAlert variant="error" title="Error." compact>
            <p>{errorMessage}</p>
          </InPageAlert>
        ) : null}

        <ComponentLoader active={isCustomerAuthLoading} fullPage />

        <Row>
          <Col span={4}>
            <FormGroup
              id={CustomerAuthFormFieldNames.clientId}
              label={CustomerAuthFormFieldLabels.clientId}
              hasError={submitCount > 0 && errors.clientId}
              errorMessage={errors.clientId}
            >
              <Input
                name={CustomerAuthFormFieldNames.clientId}
                inputWidth="xl"
                onChange={handleChange}
                value={values.clientId}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col span={4}>
            <FormGroup
              id={CustomerAuthFormFieldNames.correspondenceId}
              label={CustomerAuthFormFieldLabels.correspondenceId}
              helpMessage={getContent(
                'customerAuthForm.correspondenceId.helpMessage',
              )}
              hasError={submitCount > 0 && errors.corroId}
              errorMessage={errors.corroId}
            >
              <Input
                name={CustomerAuthFormFieldNames.correspondenceId}
                inputWidth="xl"
                onChange={handleChange}
                value={values.corroId}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col span={4}>
            <FormGroup
              id={CustomerAuthFormFieldNames.emailAddress}
              label={CustomerAuthFormFieldLabels.emailAddress}
              helpMessage={getContent(
                'customerAuthForm.emailAddress.helpMessage',
              )}
              hasError={submitCount > 0 && errors.contactEmail}
              errorMessage={errors.contactEmail}
            >
              <Input
                name={CustomerAuthFormFieldNames.emailAddress}
                inputWidth="xl"
                onChange={handleChange}
                value={values.contactEmail}
              />
            </FormGroup>
          </Col>
        </Row>
        <ButtonGroup>
          {enableWaf && (
            <Captcha
              onClose={() => {
                setSubmitCaptchaAction(null);
              }}
              onSubmit={submitCaptchaAction}
            />
          )}
          <Button
            type="submit"
            onClick={(e) => {
              // reset error messages
              setErrorMessage('');
              handleFormSubmit(e);
            }}
          >
            Authenticate
          </Button>
        </ButtonGroup>
      </form>
      {/* <Heading level={4}>
        {getContent('onboarding.privacyCollectionNotice.heading')}
      </Heading>
      {getContentWithHtml('onboarding.privacyCollectionNotice.text')} */}
      <Heading level={4}>
        {getContent('onboarding.termsOfUse.heading.second.paragraph.heading')}
      </Heading>
      <span>
        {getContent('onboarding.termsOfUse.content.first.paragraph.content1')}
      </span>
      <span>
        <NoStyleButton
          onClick={() => {
            setprivacyCollectionModalOpen(true);
          }}
        >
          <p>
            {getContent(
              'onboarding.termsOfUse.content.second.paragraph.content2',
            )}
          </p>
        </NoStyleButton>
      </span>
      {privacyCollectionModalOpen && (
        <PrivacyCollectionModal
          openModal={privacyCollectionModalOpen}
          onClose={() => setprivacyCollectionModalOpen(false)}
        />
      )}

      <CustomerAuthSuccessModal
        open={authSuccess}
        close={() => setAuthSuccess(false)}
        onContinue={onRegistrationSuccess}
      />
    </>
  );
};

export default CustomerAuthForm;
