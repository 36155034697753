import dayjs from 'dayjs';
import * as Yup from 'yup';

import { CorroTypes } from '../constants';

import { DUE_DATE_LIMIT } from './constants';

const dueDateValidation = (
  date: Date,
  action: string,
  isAtLeastOneResponseSubmitted: boolean,
) =>
  Yup.string()
    .test('date format validation', 'Invalid due date', (val) => {
      if (!val) return true;
      return dayjs(val, 'YYYY-MM-DD', true).isValid();
    })
    .test('no past dates', "Date can't be in the past", (val) => {
      if (!val) return true;
      const parsedDate = dayjs(val, 'YYYY-MM-DD', true);
      const today = dayjs().startOf('day');
      return parsedDate.isValid() && parsedDate.toDate() >= today.toDate();
    })
    .test(
      'due date must be lesser than 4 weeks',
      `Due date cannot exceed ${DUE_DATE_LIMIT[action]} weeks`,
      (val) => {
        if (isAtLeastOneResponseSubmitted) return true;
        const limitDate = dayjs(date).add(DUE_DATE_LIMIT[action] * 7, 'days');
        return (
          dayjs(val).isBefore(limitDate, 'day') ||
          dayjs(val).isSame(limitDate, 'day')
        );
      },
    )
    .required('Due date is required');

export const auditorValidationSchema = (
  date: Date,
  action: string,
  isAtLeastOneResponseSubmitted: boolean,
) => {
  return Yup.object().shape({
    dueDate: dueDateValidation(date, action, isAtLeastOneResponseSubmitted),
    recipient: Yup.string().required('Recipient selection is required'),
    messageSubject: Yup.string().required('Message subject is required'),
    message: Yup.string().required('Message is required'),
    documents: Yup.array().when('action', {
      is: () =>
        action === CorroTypes.FinalisationAdvice &&
        !isAtLeastOneResponseSubmitted,
      then: (schema) => schema.min(1, 'At least one document must be uploaded'),
      otherwise: (schema) => schema.notRequired(),
    }),
    entities: Yup.string().required('Entity selection is required'),
  });
};

export const customerValidationSchema = () => {
  return Yup.object().shape({
    messageSubject: Yup.string().required('Message subject is required'),
    message: Yup.string().required('Message is required'),
  });
};
