import { FC, useEffect } from 'react';
import { Outlet, Route, Routes, useNavigate } from 'react-router-dom';
import { toRelativeUrl } from '@okta/okta-auth-js';
import {
  LoginCallback,
  Security as OktaSecurity,
  useOktaAuth,
} from '@okta/okta-react';
import { getOktaAuth } from 'src/config/app.config';
import { SESSION_STORAGE_KEY_USER_INFO } from 'src/constants';
import environments from 'src/environments';
import { PATHS } from 'src/routes/constants';
import { LogoutSuccess } from 'src/screens/common/logoutSuccess';
import { UserLoginType } from 'src/types';

import { OktaSignIn } from './SignIn';

const RequiredAuth = () => {
  const { oktaAuth, authState } = useOktaAuth();

  const bypassAuth = environments.bypassAuthorization === 'true';
  useEffect(() => {
    if (
      bypassAuth &&
      !window.sessionStorage.getItem(SESSION_STORAGE_KEY_USER_INFO)
    ) {
      // local machine only;
      window.sessionStorage.setItem(
        SESSION_STORAGE_KEY_USER_INFO,
        JSON.stringify({
          userType: UserLoginType.Staff,
          clientId: '123',
        }),
      );
      window.dispatchEvent(new Event('storage'));
    }

    if (!bypassAuth && authState?.isAuthenticated === false) {
      const originalUri = toRelativeUrl(
        window.location.href,
        window.location.origin,
      );

      oktaAuth.signInWithRedirect({ originalUri });
    }
  }, [authState, oktaAuth, bypassAuth]);

  if (bypassAuth || authState?.isAuthenticated) {
    return <Outlet context={{ authState }} />;
  }

  return null;
};

const OktaError = ({ error }: { error: Error }) => {
  if (error.name && error.message) {
    const { name, message } = error;
    return name === 'AuthSdkError' &&
      message === 'Unable to parse a token from the url' ? null : (
      <p>
        {error.name}: {error.message}
      </p>
    );
  }
  return <p>Error: {error.toString()}</p>;
};
export const OktaAuth: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const navigate = useNavigate();

  const restoreOriginalUri = async () => {
    // todo: read okta token to get clientId;
    window.sessionStorage.setItem(
      SESSION_STORAGE_KEY_USER_INFO,
      JSON.stringify({
        userType: UserLoginType.Staff,
      }),
    );
    window.dispatchEvent(new Event('storage'));

    setTimeout(() => {
      navigate('/');
    }, 600);
  };

  return (
    <OktaSecurity
      oktaAuth={getOktaAuth()}
      restoreOriginalUri={restoreOriginalUri}
    >
      <Routes>
        <Route path={PATHS.logoutSuccess} element={<LogoutSuccess />} />
        <Route path={PATHS.login} element={<OktaSignIn />} />
        <Route
          path={PATHS.loginCallback}
          element={<LoginCallback errorComponent={OktaError} />}
        />
        <Route element={<RequiredAuth />}>{children}</Route>
      </Routes>
    </OktaSecurity>
  );
};
