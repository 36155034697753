import { useEffect } from 'react';

const useScriptWithInline = (
  scriptId: string,
  src: string,
  inlineId: string,
  inlineCode: string,
  async = true,
  onLoad?: () => void,
  onError?: () => void,
) => {
  useEffect(() => {
    if (!src) return;

    // Check if the external script already exists
    const existingScript = document.getElementById(scriptId);
    if (!existingScript) {
      const script = document.createElement('script');
      script.id = scriptId;
      script.src = src;
      script.async = async;

      script.onload = () => {
        onLoad?.();

        if (!document.getElementById(inlineId)) {
          const inlineScript = document.createElement('script');
          inlineScript.id = inlineId;
          inlineScript.type = 'text/javascript';
          inlineScript.text = inlineCode;
          document.body.appendChild(inlineScript);
        }
      };

      script.onerror = () => {
        onError?.();
      };

      document.body.appendChild(script);
    }

    // Cleanup function
    // eslint-disable-next-line consistent-return
    return () => {
      const externalScript = document.getElementById(scriptId);
      if (externalScript) {
        externalScript.remove();
      }
      const inlineScript = document.getElementById(inlineId);
      if (inlineScript) {
        inlineScript.remove();
      }
    };
  }, [scriptId, src, inlineId, inlineCode, async, onLoad, onError]);
};

export default useScriptWithInline;
