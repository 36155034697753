import { VALID_FILE_FORMATS } from 'src/constants';

export const getValidInvalidFilesArray = (uploadedFiles: File[]) => {
  const { validFiles, invalidFiles } = uploadedFiles.reduce(
    (acc, file) => {
      if (file.size !== 0) {
        const isMsgOrRarExtension =
          file.type === ''
            ? file.name.endsWith('.msg') || file.name.endsWith('.rar')
            : false;
        if (VALID_FILE_FORMATS.includes(file.type) || isMsgOrRarExtension) {
          acc.validFiles.push(file);
        } else {
          acc.invalidFiles.push(file);
        }
      } else {
        acc.invalidFiles.push(file);
      }
      return acc;
    },
    { validFiles: [] as File[], invalidFiles: [] as File[] },
  );

  return { validFiles, invalidFiles };
};
