import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  Col,
  ComponentLoader,
  ContentContainer,
  FormGroup,
  Input,
  Row,
  StatusLabel,
  Textarea,
} from '@snsw/react-component-library';
import { Breadcrumbs, ErrorSummary } from 'ams-common';
import { useFormik } from 'formik';
import { useClients } from 'src/components/clients/hooks';
import { VirusDetectedUploadFailure } from 'src/components/fileUploadErrors';
import { useMatter } from 'src/context/MatterContext';
import {
  useAuditorDetails,
  useUploadDocument,
  useUserContext,
} from 'src/hooks';
import { useMatterCorrespondenceResponses } from 'src/hooks/correspondence/hooks';
import {
  useDocumentsDetails,
  useMatterDocuments,
} from 'src/hooks/document/hooks';
import { MatterDocuments, UploadDocument } from 'src/hooks/document/types';
import { PATHS } from 'src/routes/constants';
import { getMattersBreadcrumbs } from 'src/screens/common/matters/utils';
import { UserLoginType } from 'src/types';
import getContent, { getContentWithReplacements } from 'src/utils/contentUtils';

import { MessageType } from '../../constants';

import { ConfirmSubmitModal } from './modals/confirmSubmitModal';
import { SaveAsDraftModal } from './modals/saveAsDraftModal';
import { ClassificationsSections } from './classificationSections';
import {
  constructSectionDetailsFromUploadedDocuments,
  flattenObject,
  getAuditCommencementDocumentsPayload,
  getDocumentsPayload,
  getResponseToAuditCommencementInitValues,
  getUploadedDocumentIds,
  groupDataByClassification,
  updateSectionValuesWithUpdatedDocumentIds,
  updateSummaryErrorMessages,
} from './helpers';
import {
  useCreateUpdateResponseToAuditCommencement,
  useResponseToAuditCommencementDocuments,
} from './hooks';
import { ButtonGroup, StyledErrorContainer, StyledHeading } from './styles';
import {
  SaveAs,
  SubmitResponseToAuditCommencementDocumentsFormValues,
  UploadedDocument,
} from './types';
import { validationSchema } from './validation';

const initialValues: SubmitResponseToAuditCommencementDocumentsFormValues = {
  recipient: '',
  messageSubject: '',
  message: '',
  sections: null,
};

export const SubmitResponseToAuditCommencementDocuments = () => {
  const { correspondenceId: draftCorrespondenceId = '' } = useParams();
  const navigate = useNavigate();
  const {
    matterUId,
    matterId,
    matterCorrespondenceThreadId: threadId,
  } = useMatter();
  const [messageCharCount, setMessageCharCount] = useState(0);
  const [dueDate, setDueDate] = useState('');
  const [uploadedDocuments, setUploadedDocuments] = useState<string[]>([]);
  const [openSaveAsDraftModal, setOpenSaveAsDraftModal] = useState(false);
  const [openConfirmSubmitModal, setOpenConfirmSubmitModal] = useState(false);
  const [
    isAtLeastOneResponseFromCustomerSubmitted,
    setIsAtLeastOneResponseFromCustomerSubmitted,
  ] = useState(false);
  const [
    documentsGroupedByClassification,
    setDocumentsGroupedByClassification,
  ] = useState<{
    [key: string]: MatterDocuments;
  }>({});
  const [isFilesUploading, setIsFilesUploading] = useState(false);
  const [quarantinedDocuments, setQuarantinedDocuments] = useState<
    { documentName: string; documentS3Key: string }[]
  >([]);
  const errorSummaryRef = useRef<HTMLDivElement>(null);
  const isCustomer = useUserContext()?.userType === UserLoginType.Customer;
  const { clientOptions } = useClients(matterUId, isCustomer);
  const { data: auditorDetails, isLoading: isAuditorDetailsLoading } =
    useAuditorDetails(isCustomer);
  const { uploadDocumentByMatterUId, updateCorrespondenceDocument } =
    useUploadDocument();
  const navigateSuccess = (statusCode: number, saveAs: SaveAs) => {
    if (statusCode === 200 || statusCode === 201) {
      navigate(`${PATHS.matters}/${matterId}${PATHS.threads}`, {
        state: {
          messageType: MessageType.Success,
          message:
            saveAs === SaveAs.Draft
              ? 'Saved as draft'
              : 'Response submitted successfully',
        },
      });
    }
  };

  const {
    mutate: createUpdateResponseToAuditCommencement,
    isLoading: isCreateUpdateResponseToAuditCommencement,
  } = useCreateUpdateResponseToAuditCommencement(navigateSuccess);
  const {
    data: responseToAuditCommencementDocuments,
    isLoading: isResponseToAuditCommencementDocumentsLoading,
  } = useResponseToAuditCommencementDocuments(
    matterUId,
    threadId,
    draftCorrespondenceId,
  );

  const { data: uploadedDocumentDetails, refetch } = useDocumentsDetails(
    true,
    matterUId,
    uploadedDocuments,
  );
  const {
    data: correspondenceResponses,
    isLoading: isCorrespondenceResponsesLoading,
  } = useMatterCorrespondenceResponses(true, matterUId, threadId);

  const {
    data: customerMatterDocuments,
    isLoading: isCustomerMatterDocumentsLoading,
  } = useMatterDocuments(matterUId, isCustomer);

  const handleFormSubmit = async (
    values: SubmitResponseToAuditCommencementDocumentsFormValues,
    saveAs = SaveAs.Submit,
  ) => {
    try {
      let documents: { data: UploadedDocument[] } | null = null;
      let alreadyUploadedDocuments: UploadDocument[] | null = null;
      if (matterUId && values.sections) {
        alreadyUploadedDocuments = getDocumentsPayload(values.sections, {
          isUploaded: true,
        });

        const newDocuments = getDocumentsPayload(values.sections, {
          isUploaded: false,
        });

        // for the first time we have to call documents manager post request
        if (!draftCorrespondenceId) {
          documents = (await uploadDocumentByMatterUId(
            matterUId,
            newDocuments,
            true,
          )) as {
            data: UploadedDocument[];
          };
        } else if (threadId && draftCorrespondenceId) {
          documents = (await updateCorrespondenceDocument(
            matterUId,
            threadId,
            draftCorrespondenceId,
            [...newDocuments, ...alreadyUploadedDocuments],
            true,
          )) as {
            data: UploadedDocument[];
          };
        }
      }

      if (documents && documents.data) {
        const { validDocuments, invalidDocuments } = documents.data.reduce(
          (acc, document) => {
            if (!document.errorMessage) {
              acc.validDocuments.push(document);
            } else {
              acc.invalidDocuments.push(document);
            }
            return acc;
          },
          {
            validDocuments: [] as UploadedDocument[],
            invalidDocuments: [] as UploadedDocument[],
          },
        );

        const sectionValuesWithUpdatedDocumentIds =
          updateSectionValuesWithUpdatedDocumentIds(
            values.sections,
            validDocuments,
          );

        const invalidDocumentS3Keys = invalidDocuments.map((doc) => doc.s3Key);
        const invalidDocumentDetails = Object.entries(
          values.sections ?? {},
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
        ).flatMap(([_, sectionDocuments]) =>
          sectionDocuments
            .filter(
              (sectionDoc) =>
                sectionDoc.fileId &&
                invalidDocumentS3Keys.includes(sectionDoc.fileId),
            )
            .map((sectionDoc) => ({
              documentName: sectionDoc.fileName,
              documentS3Key: sectionDoc.fileId || '',
            })),
        );

        if (invalidDocuments.length > 0) {
          setQuarantinedDocuments(invalidDocumentDetails);
          return;
        }

        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        setFieldValue('sections', sectionValuesWithUpdatedDocumentIds);
      }

      const payload = getAuditCommencementDocumentsPayload(
        values,
        dueDate,
        documents,
        alreadyUploadedDocuments,
      );

      if (matterUId && threadId) {
        const isReply = draftCorrespondenceId
          ? false
          : isAtLeastOneResponseFromCustomerSubmitted;

        await createUpdateResponseToAuditCommencement([
          matterUId,
          threadId,
          draftCorrespondenceId,
          payload,
          saveAs,
          isReply,
        ]);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleSaveAsDraft = async (
    values: SubmitResponseToAuditCommencementDocumentsFormValues,
  ) => {
    await handleFormSubmit(values, SaveAs.Draft);
    setOpenSaveAsDraftModal(false);
  };

  const handleBack = () =>
    navigate(`${PATHS.matters}/${matterId}${PATHS.threads}`);

  const {
    values,
    handleChange,
    submitCount,
    errors,
    setValues,
    isSubmitting,
    isValid,
    setFieldValue,
    handleSubmit,
    setSubmitting,
  } = useFormik({
    initialValues,
    validationSchema: validationSchema(
      isAtLeastOneResponseFromCustomerSubmitted,
      documentsGroupedByClassification,
    ),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: () => {
      setOpenConfirmSubmitModal(true);
    },
  });

  useEffect(() => {
    if (!isValid && submitCount > 0) {
      errorSummaryRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isValid, submitCount]);

  useEffect(() => {
    if (!correspondenceResponses || draftCorrespondenceId) {
      return;
    }
    const { dueDate, subject } =
      correspondenceResponses[correspondenceResponses.length - 1];
    setFieldValue('recipient', auditorDetails?.fullName);
    setFieldValue('messageSubject', subject);
    setDueDate(dueDate ?? '');
  }, [
    auditorDetails?.fullName,
    correspondenceResponses,
    draftCorrespondenceId,
    setFieldValue,
  ]);

  useEffect(() => {
    const fetchData = async () => {
      if (!responseToAuditCommencementDocuments) {
        return;
      }

      const documents = getUploadedDocumentIds(
        responseToAuditCommencementDocuments,
      );
      setUploadedDocuments(documents);
      await refetch();
    };
    fetchData();
  }, [refetch, responseToAuditCommencementDocuments]);

  useEffect(() => {
    if (!responseToAuditCommencementDocuments) {
      return;
    }

    const initValuesFromResponse = getResponseToAuditCommencementInitValues(
      responseToAuditCommencementDocuments,
    );

    setValues({
      ...initValuesFromResponse,
    });

    setMessageCharCount(initValuesFromResponse.message.length ?? 0);
  }, [responseToAuditCommencementDocuments, setValues]);

  useEffect(() => {
    if (
      !uploadedDocumentDetails ||
      (uploadedDocumentDetails && uploadedDocumentDetails).length === 0 ||
      !draftCorrespondenceId
    ) {
      return;
    }
    const sectionDetailsFromUploadedDocuments =
      constructSectionDetailsFromUploadedDocuments(uploadedDocumentDetails);
    setFieldValue('sections', sectionDetailsFromUploadedDocuments);
  }, [draftCorrespondenceId, setFieldValue, uploadedDocumentDetails]);

  useEffect(() => {
    if (!correspondenceResponses) {
      return;
    }
    const responseFromCustomerSubmitted = correspondenceResponses.some(
      (response) =>
        response?.corroStatus === 'Sent' && response.replyFrom === 'CUSTOMER',
    );
    setIsAtLeastOneResponseFromCustomerSubmitted(responseFromCustomerSubmitted);
  }, [correspondenceResponses]);

  useEffect(() => {
    if (!customerMatterDocuments) {
      return;
    }

    setDocumentsGroupedByClassification(
      groupDataByClassification(customerMatterDocuments),
    );
  }, [customerMatterDocuments]);

  return (
    <>
      <ComponentLoader
        active={
          isResponseToAuditCommencementDocumentsLoading ||
          isCreateUpdateResponseToAuditCommencement ||
          isCorrespondenceResponsesLoading ||
          isCustomerMatterDocumentsLoading ||
          isAuditorDetailsLoading
        }
        fullPage
      />
      <ComponentLoader
        label="Document(s) uploading, Please wait..."
        active={isFilesUploading}
        fullPage
      />
      <ContentContainer>
        <Breadcrumbs
          paths={getMattersBreadcrumbs(
            'threads',
            'threads.submitResponseToAuditCommencementDocuments',
            matterId,
          )}
          isAccessible={!isCustomer}
        />
        <Row>
          <Col span={12}>
            <StyledHeading level={2}>
              {draftCorrespondenceId ? <StatusLabel text="Draft" /> : null}
              {getContent(
                'correspondence.customer.submitResponseToAuditCommencementsDocuments.heading',
              )}
            </StyledHeading>
          </Col>
        </Row>
        <StyledErrorContainer
          ref={errorSummaryRef}
          showError={!isValid && submitCount > 0}
        />
        <ErrorSummary
          errors={flattenObject(updateSummaryErrorMessages(errors))}
        />
        <form>
          <Row>
            <Col span={8}>
              <FormGroup id="recipient" label="Recipient">
                <Input
                  name="recipient"
                  inputWidth="xl"
                  placeholder="<<Auditor name>>"
                  value={values.recipient}
                  disabled
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <FormGroup
                name="messageSubject"
                id="messageSubject"
                label="Message Subject"
                errorMessage={errors.messageSubject}
                hasError={errors.messageSubject}
              >
                <Input
                  name="messageSubject"
                  inputWidth="xl"
                  placeholder="<<Subject from correspondence>>"
                  value={values.messageSubject}
                  onChange={handleChange}
                  maxLength={100}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <FormGroup
                name="message"
                id="message"
                label="Message"
                helpMessage={
                  <span>
                    {getContentWithReplacements(
                      'correspondence.customer.submitResponseToAuditCommencementsDocuments.message.helpMessage.text',
                      {
                        remChar: (1000 - messageCharCount).toString(),
                      },
                    )}
                  </span>
                }
                errorMessage={errors.message}
                hasError={errors.message}
              >
                <Textarea
                  name="message"
                  inputWidth="xxl"
                  placeholder="<<Message from customer>>"
                  maxLength={1000}
                  value={values.message}
                  onChange={(e) => {
                    handleChange(e);
                    setMessageCharCount(e.target.value.length);
                  }}
                />
              </FormGroup>
            </Col>
          </Row>

          {clientOptions ? (
            <ClassificationsSections
              entities={clientOptions}
              matterUId={matterUId}
              isCustomer
              setFieldValue={setFieldValue}
              values={values}
              errors={errors}
              submitCount={submitCount}
              setIsFilesUploading={setIsFilesUploading}
            />
          ) : null}

          <ButtonGroup>
            {!isAtLeastOneResponseFromCustomerSubmitted ? (
              <Button
                variant="secondary"
                onClick={() => setOpenSaveAsDraftModal(true)}
              >
                {!draftCorrespondenceId ? 'Save as draft' : 'Update draft'}
              </Button>
            ) : (
              <Button variant="secondary" onClick={handleBack}>
                Back
              </Button>
            )}
            <Button
              type="submit"
              onClick={handleSubmit}
              disabled={isSubmitting}
            >
              Submit
            </Button>
          </ButtonGroup>
        </form>
        <SaveAsDraftModal
          open={openSaveAsDraftModal}
          close={() => setOpenSaveAsDraftModal(false)}
          handleSaveAsDraft={() => handleSaveAsDraft(values)}
        />
        <ConfirmSubmitModal
          open={openConfirmSubmitModal}
          close={() => {
            setOpenConfirmSubmitModal(false);
            setSubmitting(false);
          }}
          handleConfirmSubmit={async () => {
            await handleFormSubmit(values);
            setOpenConfirmSubmitModal(false);
            setSubmitting(false);
          }}
        />
        <VirusDetectedUploadFailure
          open={quarantinedDocuments.length > 0}
          close={() => setQuarantinedDocuments([])}
          documents={quarantinedDocuments || []}
        />
      </ContentContainer>
    </>
  );
};
