import environments from 'src/environments';

import {
  FEEDBACK_ASSIST_INLINE_SCRIPT_ID,
  FEEDBACK_ASSIST_SCRIPT_ID,
} from './constants';
import useScriptWithInline from './useScriptWithInline';

const FeedbackAssist = () => {
  useScriptWithInline(
    FEEDBACK_ASSIST_SCRIPT_ID,
    environments.feedbackAssistSourceUrl,
    FEEDBACK_ASSIST_INLINE_SCRIPT_ID,
    `caBoootstrap.init("${environments.feedbackAssistFooterSourceUrl}");`,
    true,
    () => console.log('Feedback Assist script loaded successfully'),
    () => console.error('Failed to load Feedback Assist script'),
  );

  return null;
};

export default FeedbackAssist;
