import { useQuery } from 'react-query';
import { AMS_PORTAL_EXTERNAL_BFF, APIResource, queryApi } from 'src/api';
import { QueryKey } from 'src/api/constants';

import {
  AuditorDetailCodec,
  CurrentContactDetailsCodec,
  OnboardingDataCodec,
  PortalContactSummaryCodec,
} from './types';

const fetch = () => {
  return queryApi(
    {
      baseUrl: AMS_PORTAL_EXTERNAL_BFF,
      resourcePath: APIResource.contacts,
      suffix: ['contacts', 'current', 'details'],
    },
    CurrentContactDetailsCodec,
  );
};

export const useCurrentContactDetails = (isCustomer: boolean) =>
  useQuery([QueryKey.CURRENT_CONTACT_DETAILS], () => fetch(), {
    enabled: isCustomer,
  });

const fetchAuditorDetails = () => {
  return queryApi(
    {
      resourcePath: APIResource.userProfile,
      suffix: ['profiles', 'auditor'],
      baseUrl: AMS_PORTAL_EXTERNAL_BFF,
    },
    AuditorDetailCodec,
  );
};

export const useAuditorDetails = (isCustomer: boolean) =>
  useQuery(
    [QueryKey.MATTER_CONTACTS_AUDITOR_DETAILS],
    () => fetchAuditorDetails(),
    { enabled: isCustomer },
  );

const fetchOnboardingData = (matterUId: string | null, isCustomer: boolean) => {
  return queryApi(
    {
      ...(isCustomer && { baseUrl: AMS_PORTAL_EXTERNAL_BFF }),
      resourcePath: APIResource.contacts,
      suffix: ['matters', `${matterUId}`, 'clients', 'onboard'],
    },
    OnboardingDataCodec,
  );
};
// This is to  get list of contacts for a matter
export const useOnboarding = (matterUId: string | null, isCustomer: boolean) =>
  useQuery(
    [QueryKey.ONBOARDING_DATA, matterUId],
    () => fetchOnboardingData(matterUId, isCustomer),
    {
      enabled: Boolean(matterUId),
    },
  );

const fetchPortalContactSummary = (isCustomer: boolean, contactId: string) => {
  return queryApi(
    {
      ...(isCustomer && { baseUrl: AMS_PORTAL_EXTERNAL_BFF }),
      resourcePath: APIResource.contacts,
      suffix: ['contacts', `${contactId}`, 'summary'],
    },
    PortalContactSummaryCodec,
  );
};

export const usePortalContactSummary = (
  isCustomer: boolean,
  contactId: string,
) =>
  useQuery(
    [QueryKey.PORTAL_CONTACT_SUMMARY, contactId],
    () => fetchPortalContactSummary(isCustomer, contactId),
    {
      enabled: !!contactId,
    },
  );

export const usePortalContactsSummary = (
  isCustomer: boolean,
  contactIds?: string[],
) =>
  useQuery(
    [QueryKey.PORTAL_CONTACT_SUMMARY, contactIds?.join(',')],
    async () => {
      if (!contactIds?.length) return null;
      const contactSummaries = await Promise.all(
        contactIds.map((contactId) =>
          fetchPortalContactSummary(isCustomer, contactId),
        ),
      );

      return contactSummaries;
    },
    {
      enabled: !!contactIds?.length,
    },
  );
