import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
} from 'react-query';
import {
  AMS_PORTAL_EXTERNAL_BFF,
  APIResource,
  Method,
  MethodTypes,
  queryApi,
  requestApi,
} from 'src/api';
import { QueryKey } from 'src/api/constants';

import { RequestType } from '../types';

import {
  CorrespondenceDetailsCodec,
  CorrespondenceRequestBody,
  DueDateValues,
  MatterCorrespondenceDetailResponsesCodec,
  MatterCorrespondenceResultsCodec,
  MatterCorrespondenceUploadToTrimResponse,
  MatterThreadDetailsCodec,
} from './types';

const updateDueDate = (
  matterUId: string | null,
  threadId: string | null,
  payload: DueDateValues,
  requestType = MethodTypes.PUT,
) => {
  return requestApi(
    {
      resourcePath: APIResource.correspondenceManager,
      suffix: ['matters', `${matterUId}`, 'threads', `${threadId}`, 'due-date'],
    },
    requestType,
    payload,
    undefined,
    undefined,
    {
      'content-type': 'application/json',
    },
  );
};

export const useUpdateDueDate = (
  matterUId: string | null,
  threadId: string | null,
  navigateError: () => void,
  navigateSuccess: () => void,
) => {
  const queryClient = useQueryClient();
  return useMutation(
    ([payload, requestType = MethodTypes.PUT]: [
      DueDateValues,
      MethodTypes,
    ]) => {
      return updateDueDate(matterUId, threadId, payload, requestType);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          QueryKey.MATTERS_CORRESPONDENCES,
          matterUId,
        ]);
        queryClient.invalidateQueries([
          QueryKey.CORRESPONDENCE_DETAILS,
          matterUId,
          threadId,
        ]);
        queryClient.invalidateQueries([
          QueryKey.MATTER_THREAD_DETAILS,
          matterUId,
          threadId,
        ]);
        queryClient.invalidateQueries([QueryKey.ACTION_ITEMS]);

        navigateSuccess();
      },
      onError: () => {
        navigateError();
      },
    },
  );
};

const fetchCorrespondence = (
  matterUId: string | null,
  threadId: string | null,
  correspondenceId: string | null,
  isCustomer: boolean = false,
) => {
  return queryApi(
    {
      ...(isCustomer && { baseUrl: AMS_PORTAL_EXTERNAL_BFF }),
      resourcePath: APIResource.correspondenceManager,
      suffix: [
        'matters',
        `${matterUId}`,
        'threads',
        `${threadId}`,
        'correspondences',
        `${correspondenceId}`,
      ],
    },
    CorrespondenceDetailsCodec,
  );
};

export const useCorrespondenceDetails = (
  matterUId: string | null,
  threadId: string | null,
  correspondenceId: string | null,
  isCustomer: boolean = false,
) => {
  const queryKey = [
    QueryKey.CORRESPONDENCE_DETAILS,
    matterUId,
    threadId,
    correspondenceId,
  ];
  return useQuery(
    queryKey,
    () =>
      fetchCorrespondence(matterUId, threadId, correspondenceId, isCustomer),
    {
      cacheTime: 0,
      enabled: !!matterUId && !!threadId && !!correspondenceId,
    },
  );
};

const upsertCorrespondence = (
  matterUId: string,
  payload: CorrespondenceRequestBody,
  requestType: RequestType = RequestType.Add,
  threadId?: string,
  correspondenceId?: string,
  saveAs?: 'draft',
  isCustomer: boolean = false,
  isReply: boolean = false,
) => {
  const suffix = ['matters', matterUId, 'threads'];

  if (threadId) {
    suffix.push(threadId, 'correspondences');
    if (correspondenceId && requestType === RequestType.Update) {
      suffix.push(correspondenceId);
    } else if (!correspondenceId && !isReply) {
      suffix.push('response');
    }
    if (isReply) {
      suffix.push('reply');
    }
  }

  if (saveAs) {
    suffix.push(saveAs);
  }

  const verb =
    requestType === RequestType.Add ? MethodTypes.POST : MethodTypes.PUT;

  // Prepare the API request
  return requestApi(
    {
      ...(isCustomer && { baseUrl: AMS_PORTAL_EXTERNAL_BFF }),
      resourcePath: APIResource.correspondenceManager,
      suffix,
    },
    verb,
    payload,
    undefined,
    undefined,
    {
      'content-type': 'application/json',
    },
  );
};

export const useCreateUpdateCorrespondence = (
  navigateSuccess: (statusCode: number, saveAs?: 'draft') => void,
  navigateError: (data: {
    status: number;
    code: string;
    messages?: string[];
  }) => void,
) => {
  const queryClient = useQueryClient();
  return useMutation(
    ([
      matterUId,
      payload,
      requestType = RequestType.Add,
      threadId,
      correspondenceId,
      saveAs,
      isCustomer,
      isReply,
    ]: [
      string,
      CorrespondenceRequestBody,
      RequestType,
      string | undefined,
      string | undefined,
      'draft' | undefined,
      boolean | undefined,
      boolean | undefined,
    ]) => {
      return upsertCorrespondence(
        matterUId,
        payload,
        requestType,
        threadId,
        correspondenceId,
        saveAs,
        isCustomer,
        isReply,
      );
    },
    {
      onSuccess: (data, variables) => {
        const [matterUId, , requestType, threadId, correspondenceId, saveAs] =
          variables;

        queryClient.invalidateQueries([
          QueryKey.MATTERS_CORRESPONDENCES,
          matterUId,
        ]);
        queryClient.invalidateQueries([QueryKey.MATTERS]);
        queryClient.invalidateQueries([QueryKey.MATTER_DOCUMENTS, matterUId]);
        queryClient.invalidateQueries([
          QueryKey.MATTERS_CORRESPONDENCE_RESPONSES,
          matterUId,
          threadId,
        ]);
        queryClient.invalidateQueries([
          QueryKey.MATTER_THREAD_DETAILS,
          matterUId,
          threadId,
        ]);

        queryClient.invalidateQueries([
          QueryKey.CORRESPONDENCE_DETAILS,
          matterUId,
          threadId,
          correspondenceId,
        ]);

        console.log(
          `Correspondence successfully ${
            requestType === RequestType.Add ? 'added' : 'updated'
          }`,
        );
        navigateSuccess((data as any).status, saveAs);
      },
      onError: (error, variables) => {
        const [, , requestType] = variables;
        console.log(
          `Correspondence is failed to ${
            requestType === RequestType.Add ? 'add' : 'update'
          }`,
        );
        navigateError(error as any);
      },
    },
  );
};

const fetchMatterCorrespondenceResponses = (
  isCustomer: boolean,
  matterUId?: string | null,
  threadId?: string | null,
) => {
  return queryApi(
    {
      ...(isCustomer && { baseUrl: AMS_PORTAL_EXTERNAL_BFF }),
      resourcePath: APIResource.correspondenceManager,
      suffix: [
        'matters',
        `${matterUId}`,
        'threads',
        `${threadId}`,
        'correspondences',
      ],
    },
    MatterCorrespondenceDetailResponsesCodec,
  );
};

export const useMatterCorrespondenceResponses = (
  isCustomer: boolean,
  matterUId?: string | null,
  threadId?: string | null,
) =>
  useQuery(
    [QueryKey.MATTERS_CORRESPONDENCE_RESPONSES, matterUId, threadId],
    () => fetchMatterCorrespondenceResponses(isCustomer, matterUId, threadId),
    { enabled: !!matterUId && !!threadId },
  );

const fetchAuditorCorrespondence = (matterUId?: string | null) => {
  return queryApi(
    {
      resourcePath: APIResource.correspondenceManager,
      suffix: ['matters', `${matterUId}`, 'threads'],
    },
    MatterCorrespondenceResultsCodec,
  );
};

const fetchCustomerCorrespondence = (matterUId?: string | null) => {
  return queryApi(
    {
      baseUrl: AMS_PORTAL_EXTERNAL_BFF,
      resourcePath: APIResource.correspondenceManager,
      suffix: ['matters', `${matterUId}`, 'threads'],
    },
    MatterCorrespondenceResultsCodec,
  );
};

export const useMatterCorrespondences = (
  isCustomer: boolean,
  matterUId?: string | null,
  disabled?: boolean,
) => {
  return useQuery(
    [QueryKey.MATTERS_CORRESPONDENCES, matterUId],
    () =>
      isCustomer
        ? fetchCustomerCorrespondence(matterUId)
        : fetchAuditorCorrespondence(matterUId),
    {
      enabled: !!matterUId && !disabled,
    },
  );
};

const fetchMatterThreadDetails = (
  isCustomer: boolean,
  matterUId?: string | null,
  threadId?: string | null,
) => {
  return queryApi(
    {
      ...(isCustomer && { baseUrl: AMS_PORTAL_EXTERNAL_BFF }),
      resourcePath: APIResource.correspondenceManager,
      suffix: ['matters', `${matterUId}`, 'threads', `${threadId}`],
    },
    MatterThreadDetailsCodec,
  );
};

export const useMatterThreadDetails = (
  isCustomer: boolean,
  matterUId?: string | null,
  threadId?: string | null,
) => {
  return useQuery(
    [QueryKey.MATTER_THREAD_DETAILS, matterUId, threadId],
    () => fetchMatterThreadDetails(isCustomer, matterUId, threadId),
    {
      enabled: !!matterUId && !!threadId,
    },
  );
};

const uploadCorrespondenceToTrim = async ({
  portalMatterId,
  threadId,
  file,
  fileName,
  clientId,
  trimRecordTitle,
  trimDescription,
}: {
  portalMatterId: string;
  threadId: string;
  file: File;
  fileName: string;
  clientId: number;
  trimRecordTitle: string;
  trimDescription: string;
}): Promise<MatterCorrespondenceUploadToTrimResponse> => {
  const formData = new FormData();
  const jsonPayload = JSON.stringify({
    clientId,
    trimRecordTitle,
    trimDescription,
    fileName,
  });

  formData.append(
    'fileUploadRequest',
    new Blob([jsonPayload], {
      type: 'application/json',
    }),
  );

  formData.append('multiPartfile', file, file.name);

  return requestApi<MatterCorrespondenceUploadToTrimResponse>(
    {
      resourcePath: APIResource.correspondenceManager,
      suffix: [
        'matters',
        `${portalMatterId}`,
        'threads',
        `${threadId}`,
        'trim',
        'upload',
      ],
    },
    'POST' as Method,
    formData,
    undefined,
    'json',
  );
};

export const useMatterCorrespondenceUploadToTrim = (
  portalMatterId: string | null,
  threadId: string | null,
  navigateSuccess: (data: MatterCorrespondenceUploadToTrimResponse) => void,
  navigateError: () => void,
): UseMutationResult<
  MatterCorrespondenceUploadToTrimResponse,
  Error,
  {
    file: File;
    fileName: string;
    clientId: number;
    trimRecordTitle: string;
    trimDescription: string;
  }
> => {
  const queryClient = useQueryClient();
  return useMutation<
    MatterCorrespondenceUploadToTrimResponse,
    Error,
    {
      file: File;
      fileName: string;
      clientId: number;
      trimRecordTitle: string;
      trimDescription: string;
    }
  >(
    ({ file, fileName, clientId, trimRecordTitle, trimDescription }) => {
      if (!portalMatterId) {
        throw new Error('Portal Matter ID is required');
      }
      if (!threadId) {
        throw new Error('Thread ID is required');
      }
      return uploadCorrespondenceToTrim({
        portalMatterId,
        threadId,
        file,
        fileName,
        clientId,
        trimRecordTitle,
        trimDescription,
      });
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries([
          QueryKey.MATTER_THREAD_DETAILS,
          portalMatterId,
          threadId,
        ]);
        queryClient.invalidateQueries([
          QueryKey.MATTERS_CORRESPONDENCES,
          portalMatterId,
        ]);
        queryClient.invalidateQueries([
          QueryKey.MATTERS_CORRESPONDENCE_RESPONSES,
          portalMatterId,
          threadId,
        ]);
        navigateSuccess(data);
      },
      onError: (error) => {
        console.error('Error uploading file:', error);
        navigateError();
      },
    },
  );
};
